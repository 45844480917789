import React from "react";

interface QuestionProps {
  q?: any;
  onAnswer?: any;
  onScanClick?: any;
  isPreview?: boolean;
  complete?: boolean;
}

const Question: React.FC<QuestionProps> = ({q, isPreview, onScanClick, onAnswer, complete}) => {
  //const [marker, setMarker] = React.useState<google.maps.Marker>();

  // React.useEffect(() => {
  //   console.log('Question loaded');
  //   return () => {
  //     console.log('Question destroyed');
  //   };
  // }, []);

  return (<div className="question" style={{backgroundColor: q.color+'e0'}}>
    {q.img && <img src={q.img} className="question_img" alt="" />}
    <h2>{q.title}</h2>
    {q.location && <h4>{q.location}</h4>}
    {!isPreview && !complete &&(<>
      <p>{q.question}</p>
        <div className="question-answers">
          {q.possible_answers.map((a,i)=>{return (<button key={i} className="btn-answer" onClick={()=>{onAnswer(q,a,i)}}>{a}</button>)})}
      </div></>)}
    {isPreview && !complete && <button className="scan-btn" onClick={onScanClick}></button>}
    {complete && <div>You have already completed this challenge, keep looking for more.</div>}
  </div>);
};


export default Question;