import axios from 'axios';

const api = axios.create();

const hostname = window.location.hostname;
//const protocol = /\.local$/.test(window.location.hostname) ? 'http://' : 'https://';

const isLocal = /local/.test(window.location.hostname);

//api.defaults.baseURL = `${protocol}${hostname}/api/`;
if(isLocal){
  api.defaults.baseURL = `http://localhost:30007/api/`;
} else {
  api.defaults.baseURL = `https://${hostname}/api/`;
}



api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('access_token');

    config.headers.common['Authorization'] = 'Bearer ' + token;

    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  });

api.interceptors.response.use(
    function (response) {
        //console.log(response);
        if(response?.data?.token) {
            localStorage.setItem('access_token', response.data.token);
        }
        return response;
    }, 
    function (error) {
        console.log(error);
        // if(error.response && error.response.status === 401 && !error.response.config.url.includes('/auth/login')) {
        //     localStorage.removeItem('access_token');
        //     window.location.href = '/';
        // }
        return Promise.reject(error);
    });

export default api;