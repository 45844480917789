import React from "react";
import btnEnter from '../images/button_enter_prize_draw.png';


interface EntryFormProps {
  onSubmit?: any;
  btnMessage?: any;
}


const EntryForm: React.FC<EntryFormProps> = ({onSubmit, btnMessage}) => {
  const [inputData, setInputData] = React.useState<any>({first_name:"",last_name:"",email:""});

  // React.useEffect(() => {
  //   console.log('EntryForm loaded');
  //   return () => {
  //     console.log('EntryForm destroyed');
  //   };
  // }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(inputData);

    if(!inputData.first_name || !inputData.last_name || !inputData.email){
      return;
    }

    onSubmit(inputData);
  }



  return (
    <form onSubmit={handleSubmit}>
      <div className="input-row"><label>First Name</label>
        <input 
          type="text"
          name="first_name"
          required
          value={inputData.first_name}
          onChange={(e) => setInputData((obj)=>({...obj, first_name:e.target.value}))}
        />
      </div>
      <div className="input-row"><label>Last Name</label>
        <input 
          type="text"
          name="last_name"
          required
          value={inputData.last_name}
          onChange={(e) => setInputData((obj)=>({...obj, last_name:e.target.value}))}
        />
      </div>
      <div className="input-row"><label>Email</label>
        <input 
          type="email"
          name="email"
          required
          value={inputData.email}
          onChange={(e) => setInputData((obj)=>({...obj, email:e.target.value}))}
        />
      </div>
      <p style={{marginBottom:"30px"}}><a href="downtown-tauranga-explorer-trail-terms.pdf" target="_blank">Terms and conditions</a> apply.</p>
      <button className="btn btn-pink" type="submit">
        {btnMessage || "Enter Prize Draw"}
      </button>
    </form>
  )
};


export default EntryForm;