import apiClient from './apiClient';


export function getChallenges() {
    return apiClient.get('/challenges');
}

export function updateUser(obj) {
    return apiClient.post('/user', obj).then((resp)=>{
        console.log(resp);
        return resp;
    });
}

export function saveAnswer(obj) {
    return apiClient.post('/answer', obj).then((resp)=>{
        console.log(resp);
        return resp;
    });
}





