import * as React from "react";
//import { createRoot } from "react-dom/client";
//import { Wrapper, Status } from "@googlemaps/react-wrapper";
//import { createCustomEqual } from "fast-equals";
//import { isLatLngLiteral } from "@googlemaps/typescript-guards";

import markerActive from '../images/marker.svg';
import markerComplete from '../images/marker-complete.svg';
import markerMe from '../images/marker-complete.svg';


interface MarkerProps extends google.maps.MarkerOptions {
  onClick?: (e: google.maps.MapMouseEvent, marker?:google.maps.Marker) => void;
  isActive?: boolean;
  markerType: string;
}


const Marker: React.FC<MarkerProps> = ({onClick, isActive, markerType, ...options}) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
      	console.log("Removing Marker");
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      if(markerType === 'complete'){
        options.icon = {
          //path: google.maps.SymbolPath.CIRCLE,
          url: markerComplete,
          //size: new google.maps.Size(150, 224),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(15, 45),
          scaledSize: new google.maps.Size(30, 45)
        }
        options.shape = {
          coords: [0, 0, 0, 45, 30, 45, 30, 0],
          type: "poly",
        }
      } else if(markerType === 'normal'){
          options.icon = {
            url: markerActive,
            //size: new google.maps.Size(150, 224),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 45),
            scaledSize: new google.maps.Size(30, 45)
          }
          // options.shape = {
          //   coords: [0, 0, 0, 45, 30, 45, 30, 0],
          //   type: "poly",
          // }
      } else {
        options.icon = {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: '#4285f4',
          strokeColor: '#FFF',
          fillOpacity: 1,
          strokeWeight: 2,
          scale: 10,
        }
      }

      marker.setOptions(options);
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(marker, eventName)
      );
      if (onClick) {
        marker.addListener("click", (e:google.maps.MapMouseEvent)=>onClick(e,marker));
      }
      if (isActive) {
        marker.setAnimation(google.maps.Animation.BOUNCE);
      } else {
        marker.setAnimation(null);
      }
    }
  }, [marker, options, onClick, isActive, markerType]);

  return null;
};


export default Marker;