import React from "react";



interface PopupProps {
  children?: React.ReactNode;
  title?: string;
  show?: boolean;
  btnMessage?: string;
  btnClass?: string;
  btnImage?: any;
  mainImage?: any;
  logoImage?: any;
  imageClick?: any;
  onClick?: any;
  className?: string;
}


const Popup: React.FC<PopupProps> = ({children, title, show, btnMessage, onClick, mainImage, logoImage, imageClick, btnImage, className, btnClass}) => {
  //const [marker, setMarker] = React.useState<google.maps.Marker>();

  if(!imageClick) {
    imageClick = ()=>{};
  }

  React.useEffect(() => {
    console.log('Popup loaded');
    return () => {
      console.log('Popup destroyed');
    };
  }, []);

  className += " popup-wrap";
  if(show) {
    className += " show";
  }

  return (
    <div className={className}>
    <div className="popup" onClick={(e)=>e.stopPropagation()}>
      {mainImage && <img className="popup_image" src={mainImage} alt={title} onClick={imageClick} />}
      <h2 className="popup_title">{title}</h2>
      <div className="popup_content">{children}</div>
      {(btnMessage || btnImage) && <button className={"btn "+btnClass} onClick={onClick}>
        {btnImage ? <img src={btnImage} alt={btnMessage} /> : btnMessage}
      </button>}
      {logoImage && <img className="popup_image_bottom" src={logoImage} alt={title} />}
    </div>
    </div>
  );
};


export default Popup;