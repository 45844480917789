import React, {useState, useEffect} from 'react';
import QrReader from 'react-qr-scanner'
import './App.css';

//import btnLetsGo from './images/button_lets_go.png';
//import btnBackToMap from './images/button_back_to_map.png';
//import btnClaim from './images/button_claim_your_prize.png';
//import btnEnter from './images/button_enter_prize_draw.png';
//import btnTryAgain from './images/button_try_again.png';

//import iconCorrect from './images/icon_correct.svg';
import iconCorrect from './images/star.png';
import iconIncorrect from './images/icon_incorrect.svg';
import iconYouDidIt from './images/icon_you_did_it.svg';
import iconSound from './images/speaker.png';

import iconFind from './images/icon_find.svg';
import iconScan from './images/icon_scan.svg';
import iconEmail from './images/icon_submit_email.svg';


//import logoBlack from './images/stem_explorer_trail_logo_black.svg';
//import logoWideWhite from './images/dt-logo-wide-white.png';


//import { createRoot } from "react-dom/client";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
//import { createCustomEqual } from "fast-equals";
//import { isLatLngLiteral } from "@googlemaps/typescript-guards";

import {getChallenges, saveAnswer, updateUser} from './api/api';

import Map from "./map/Map";
import Marker from "./map/Marker";
import InfoBox from "./map/InfoBox";
import Question from './components/Question';
import Popup from './components/Popup';
import EntryForm from './components/EntryForm';

const gtag = (window as any).gtag;

const render = (status: Status) => {
	return <div></div>;//<h1>{status}</h1>;
};

const useLocalStorage = (storageKey:string, fallbackState:any) => {
  const [value, setValue] = React.useState(
    JSON.parse(window.localStorage.getItem(storageKey)) ?? fallbackState
  );

  React.useEffect(() => {
	  window.localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};


const slug = window.location.pathname;
let initialToken = null;
let match = slug.match(/\/c(?:hallenge)?\/([a-zA-Z\d]+)$/);
if (match) {
	initialToken = match[1];
}
window.history.replaceState(null,null,'/');

function App() {
	//const [completedChallenges, setCompletedChallenges] = useLocalStorage("completed", []);
	const [showInfo, setShowInfo] = useLocalStorage("showInfo", true);

	const [audio] = useState(new Audio());

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [loadingAnswer, setLoadingAnswer] = useState(false);
	//const [isInfoOpen, setIsInfoOpen] = useState(false);
	//const [infoOptions, setInfoOptions] = useState(null);

	const [showScanner, setShowScanner] = useState(false);
	const [curChallenge, setCurChallenge] = useState(0);
	const [isPreview, setIsPreview] = useState(true);

	const [popupOptions, setPopupOptions] = useState({show:false,title:'Popup', msg:''});

	const [position, setPosition] = useState({lat:0,lng:0,accuracy:0,ok:false});

	(window as any).trackLocation = !showScanner;


	const [items, setItems] = useState([]);
	const [settings, setSettings] = useState<any>({});
	const [user, setUser] = useState<any>({});
	//const [markers, setMarkers] = React.useState<google.maps.LatLng[]>([]);
	const [zoom, setZoom] = React.useState(17); // initial zoom
	const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
		//lat: -37.6863632,
		//lng: 176.16712868,
		lat:-37.6845796,
		lng:176.16913623
	});

	let totalCount = items.length;
	let completeCount = items.filter((c)=>c.complete).length;
	let remainingCount = items.filter((c)=>!c.complete).length;
	let challenge = items.find((c)=>{return c.id===curChallenge});

	let showClaim = !loading && !remainingCount && !showInfo && !popupOptions.show;
	let showSignin = !loading && !user.email && !showInfo;

	let formSubmitted = !!user.email;

	const onClick = (e: google.maps.MapMouseEvent) => {
	  setCurChallenge(0);
	};

	const onMarkerClick = (e: google.maps.MapMouseEvent, marker:google.maps.Marker, challenge) => {
		setCurChallenge(challenge.id);
		setIsPreview(true);
		getChallenges();
		gtag('event', "marker_click", {
			'event_category': "click",
			'event_label': challenge.title
		});
	};

	const onScanClick = () => {
		setShowScanner(true);
		setCurChallenge(0);
		gtag('event', "scan_click", {
			'event_category': "click"
		});
	};
	const onInfoClick = () => {
		setShowInfo(true);
		gtag('event', "info_click", {
			'event_category': "click"
		});
	};

	const getRandomMessage = () => {
		const msgs = ["You're a genius!", "That's correct, well done!", "Good job!", "Nice one!", "Correct answer!", "Yes, you're doing great!", "You're one smart cookie!", "Great job!"];
		return msgs[Math.floor(Math.random()*msgs.length)];
	};


	const replaySound = () => {
		audio.pause();
		audio.currentTime = 0;
		audio.play();
	}

	const onAnswer = (challenge, answerText, index) => {
		if(loadingAnswer){
			return;
		}
		setLoadingAnswer(true);

		

		saveAnswer({challenge_id: challenge.id, answer: index, lat: position.lat, lng: position.lng, accuracy: position.accuracy}).then(
			(result) => {
				console.log(result);
				let challenges = result.data.challenges;
				let correct = result.data.correct;
				processResponse(result);

				gtag('event', correct?"answer_correct":"answer_incorrect", {
					'event_category': "answer",
					'event_label': challenge.title,
					'answer': index
				});

				let remaining = challenges.filter((c)=>!c.complete).length;

				let soundFile = correct ? challenge.audio_correct : challenge.audio_incorrect;
				if(soundFile) {
					audio.src = soundFile;
					audio.play();
				}

				if(remaining) {
					setPopupOptions((o)=>({...o,
						show:true,
						title: correct ? (challenge.answer_title||getRandomMessage()):"Tēnā anō",
						msg: correct ? challenge.answer_blurb:"",
						//mainImage: soundFile ? iconSound : (correct ? iconCorrect:iconIncorrect),
						mainImage: soundFile ? iconSound : (correct ? iconCorrect:null),
						imageClick: soundFile ? replaySound : null,
						btnMessage: correct ? "Back to map":"Try Again",
						btnClass: "btn-blue",
						//btnImage: correct ? btnBackToMap:btnTryAgain,
					}));
				} else {
					//Completed all challenges
					setPopupOptions((o)=>({...o,
						show:true,
						title: challenge.answer_title||"You’re a genius!",
						msg: challenge.answer_blurb,
						mainImage: soundFile ? iconSound : (iconYouDidIt),
						imageClick: soundFile ? replaySound : null,
						btnMessage: "Enter the draw",
						btnClass: "btn-pink",
						//btnImage: btnClaim,
					}));
					gtag('event', "complete", {
						'event_category': "answer",
						'event_label': challenge.title,
						'answer': index
					});
				}

				if (correct) {
					setCurChallenge(0);
				}
				setLoadingAnswer(false);
				
			},
			(error) => {
				console.log(error);
				setLoadingAnswer(false);
				setError(error);
			}
		);
	};

	const submitEntry = (data) => {

		setLoadingAnswer(true);

		gtag('event', "submit_entry", {
		});

		updateUser(data).then(
			(result) => {
				processResponse(result);
				setLoadingAnswer(false);
			},
			(error) => {
				console.log(error);
				setLoadingAnswer(false);
				setError(error);
			}
		);
	};

	const onIdle = (m: google.maps.Map) => {
		setZoom(m.getZoom()!);
		setCenter(m.getCenter()!.toJSON());
	};

	const handleScan = (data) =>{
		if(data){
			let match = data.text.match(/\/c(?:hallenge)?\/([a-zA-Z\d]+)$/);
			if (match) {
				let foundChallenge = items.find((c)=>(c.token===match[1]));
				if(foundChallenge) {
					setCurChallenge(foundChallenge.id);
					setIsPreview(false);
					setShowScanner(false);
					gtag('event', "scan_challenge", {
						'event_category': "scan",
						'event_label': foundChallenge.title
					});
				}
			}
		}
	}
	const handleScanError = (err) =>{
		//console.error(err)
	}

	const processResponse = (result) =>{
		let challenges = result.data.challenges;
		setSettings(result.data.settings||{});
		setItems(challenges);
		setUser(result.data.user||{});

		let foundChallenge = challenges.find((c)=>(c.token===initialToken));
		initialToken = null;
		if(foundChallenge) {
			setCurChallenge(foundChallenge.id);
			setIsPreview(false);
			setShowScanner(false);
			gtag('event', "scan_challenge_url", {
				'event_category': "scan",
				'event_label': foundChallenge.title
			});
		}

	}

	const mediaOpts = {
		video: { facingMode: "environment" }
	}

	const closeScanner = () =>{
		setShowScanner(false);
	}

	const onPopupClick = () =>{
		setPopupOptions((o)=>({...o,show:false}));
	}


	const mapStyles = [
		{
		  featureType: "poi",
		  stylers: [{ visibility: "off" }],
		},
		{
		  featureType: "transit",
		  elementType: "labels.icon",
		  stylers: [{ visibility: "off" }],
		},
	  ];

	useEffect(() => {
		getChallenges().then(
			(result) => {
				console.log(result);
				processResponse(result);
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				setError(error);
			}
		);
	}, [])

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.watchPosition(function(position) {
				(window as any).trackLocation && setPosition({
					lat:position.coords.latitude,
					lng:position.coords.longitude,
					accuracy: position.coords.accuracy,
					ok:true,
				});
			},function(e){},{enableHighAccuracy:true});
		  }
	}, [])

	//AIzaSyAdlcKOJpmnJlm1XAJhhhsAU2ElSJjkyYM
	return (
		<div className="App">
		<header className="App-header">
		<Wrapper apiKey="AIzaSyDUKl44kC1bZkoAPPkEkxPJ0yT8xu2uj-s" render={render}>
			<Map
			center={center}
			onClick={onClick}
			onIdle={onIdle}
			zoom={zoom}
			styles={mapStyles}
			gestureHandling="greedy"
			fullscreenControl={false}
			mapTypeControlOptions={{position:6}}
			style={{ flexGrow: "1", height: "100%", width:'100%' }}
			>
			{items.map((challenge, i) => {
				let latLng = {
					lat: challenge.lat,
					lng: challenge.lng
				}
				return <Marker key={i} isActive={curChallenge===challenge.id} markerType={challenge.complete ? 'complete':'normal'} position={latLng} onClick={(e,marker)=>onMarkerClick(e,marker,challenge)} />
			})}

			{position.ok && <Marker isActive={false} markerType="location" position={position} />}

			{/* {isInfoOpen && <InfoBox onClose={()=>{setIsInfoOpen(false)}} {...infoOptions} />} */}


			</Map>
		</Wrapper>
		</header>
		<div className="header">
			<div className='logo'></div>
			<div className='progress-container'>
				<p className='progress-text'>Your Progress...</p>
				<div className='progress-wrap'>
					<div className='progress'>
						<div style={{ width: (completeCount*100/totalCount).toFixed(3)+'%',}} className='progress-bar'></div>
					</div>
					<div className='progress-number'>
						{completeCount}/{totalCount}
					</div>
				</div>
			</div>
			<button className='info-btn' onClick={onInfoClick}></button>
			<button className='scan-btn' onClick={onScanClick}></button>
		</div>



		{showScanner && <div className="scanner-wrap">
			<QrReader
				delay={500}
				//style={previewStyle}
				onError={handleScanError}
				onScan={handleScan}
				constraints={mediaOpts}
				className="scanner"
				/>
			<div className="scanner-overlay">
				
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
				<path d="M8 40L7.5 40L7.5 40.5L8 40.5L8 40ZM8.5 32L8.5 31.5L7.5 31.5L7.5 32L8.5 32ZM16 40.5L16.5 40.5L16.5 39.5L16 39.5L16 40.5ZM8.5 40L8.5 32L7.5 32L7.5 40L8.5 40ZM16 39.5L8 39.5L8 40.5L16 40.5L16 39.5Z" fill="#000"/>
				<path d="M40 8L40.5 8L40.5 7.5L40 7.5L40 8ZM39.5 16L39.5 16.5L40.5 16.5L40.5 16L39.5 16ZM32 7.5L31.5 7.5L31.5 8.5L32 8.5L32 7.5ZM39.5 8L39.5 16L40.5 16L40.5 8L39.5 8ZM32 8.5L40 8.5L40 7.5L32 7.5L32 8.5Z" fill="#000"/>
				<path d="M40 40L40 40.5L40.5 40.5L40.5 40L40 40ZM32 39.5L31.5 39.5L31.5 40.5L32 40.5L32 39.5ZM40.5 32L40.5 31.5L39.5 31.5L39.5 32L40.5 32ZM40 39.5L32 39.5L32 40.5L40 40.5L40 39.5ZM39.5 32L39.5 40L40.5 40L40.5 32L39.5 32Z" fill="#000"/>
				<path d="M8 8L8 7.5L7.5 7.5L7.5 8L8 8ZM16 8.5L16.5 8.5L16.5 7.5L16 7.5L16 8.5ZM7.5 16L7.5 16.5L8.5 16.5L8.5 16L7.5 16ZM8 8.5L16 8.5L16 7.5L8 7.5L8 8.5ZM8.5 16L8.5 8L7.5 8L7.5 16L8.5 16Z" fill="#000"/>
				</svg>
				<button className="" onClick={closeScanner}>X</button>
			</div>
		</div>}

		{challenge && <Question isPreview={isPreview} onScanClick={onScanClick} onAnswer={onAnswer} q={challenge} complete={challenge.complete} />}
		
		
		
		<Popup {...popupOptions} onClick={onPopupClick}>
			<span dangerouslySetInnerHTML={{__html:popupOptions.msg}}></span>
		</Popup>

		<Popup className="info-popup" title="Matariki Whetū Star Trail" btnMessage="Let's Go" btnClass='btn-blue' mainImage={settings.main_img} logoImage={settings.main_logo} show={showInfo} onClick={()=>setShowInfo(false)}>
			<img className="info-img info-img--find" src={iconFind} alt="Find" />
			<p>Find the 9 Whetū Stars around Downtown Tauranga.</p>
			<img className="info-img info-img--scan" src={iconScan} alt="Scan" />
			<p>Scan the QR code to open the question.</p>
			<img className="info-img info-img--email" src={iconEmail} alt="Email" />
			<p>Complete the trail and go in the draw to win prizes from Downtown Tauranga businesses, Molyneux Jewellery, Carters Photographics, Books a Plenty and The Collection.</p>
		</Popup>

		<Popup className="info-popup" title="Mānawatia a Matariki!" mainImage={settings.main_img} logoImage={settings.main_logo} show={showClaim} onClick={()=>null}>
			<h2>Go in the Draw!</h2>
			{formSubmitted ? <>
			<p>{user.first_name}, you are in the draw to win prizes from Downtown Tauranga businesses, Molyneux Jewellery, Carters Photographics, Books a Plenty and The Collection.</p>
			<p><a target='_blank' href="/downtown-tauranga-explorer-trail-terms.pdf">Terms and conditions</a> apply.</p></>
			:
			<>
				<p><b>Enter your details below to go in the draw to win prizes from Downtown Tauranga businesses, Molyneux Jewellery, Carters Photographics, Books a Plenty and The Collection.</b></p>
				<EntryForm onSubmit={submitEntry}/>
			</>
			}
		</Popup>

		<Popup className="info-popup" title="Enter your details" mainImage={settings.main_img} logoImage={settings.main_logo} show={showSignin} onClick={()=>null}>
			<p>Enter your details below, complete the trail and go in the draw to win prizes from Downtown Tauranga businesses, Molyneux Jewellery, Carters Photographics, Books a Plenty and The Collection.</p>
			{loadingAnswer ? "" : <EntryForm btnMessage="Submit" onSubmit={submitEntry}/>}
		</Popup>



		</div>
		);
}

export default App;